import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress, Dialog, DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import config from '../../../../config';
import { useTranslation } from 'react-i18next';
import axiosInstance from "../../../../Instances/axiosInstance";
import PageBanner from "../../../utils/PageBanner";

const Profile = (props) => {
  const [editProfile, setEditProfile] = useState(false);
  const [editSignIn, setEditSignIn] = useState(false);
  const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [userData, setUserData] = useState({
    firstname: '',
    lastname: '',
    phone_number: '',
    email: '',
  });

  const handleFormSubmit = async (values) => {
    setOpenDialog(true);
    setIsLoadingDialog(true);
    await axios.post(
      `${config.baseURL}/api/v1/change/user/info`,
      values,
      {
        headers: {
          Authorization: 'Bearer ' + props.token,
        },
      },
    ).catch(error => {
      console.error('Error:', error);
    });
    setOpenDialog(false);
    setIsLoadingDialog(false);
  };

  const sendChangePasswordEmail = async () => {
    setOpenDialog(true);
    setIsLoadingDialog(true);
    await axios.post(
      `${config.baseURL}/api/v1/reset-password/authorized`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + props.token,
        },
        }).then(response => {
          setDialogMessage(response.data.message);
          setIsLoadingDialog(false);

        }).catch(error => {
            console.error('Error:', error);
            setDialogMessage(error.response.data.message);
            setIsLoadingDialog(false);
        })
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const getData = async () => {
    await axiosInstance.get(
      `/api/v1/info/user/dashboard`,

    ).then(response => {
      if (response.status === 200) {
        setIsLoading(false);
        response.data.access_token && setToken(response.data.access_token);
        setUserData(response.data);
      }

    }).catch(error => {
      console.error('Error:', error);
    });
  };

  useEffect(() => {
    getData().then(r => console.log(r));
  }, []);

  const initialValues = {
    firstname: '',
    lastname: '',
    phone_number: ''
  };
  const checkoutSchema = yup.object().shape({
    firstname: yup.string().required('Required'),
    lastname: yup.string().required('Required'),
    phone_number: yup.string().matches(phoneRegExp, 'phone number is not valid!').required('Required')

  });
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'));


  return (
        <Box m="10px">
          <Grid container spacing={'10px'} rowSpacing={'10px'} columnSpacing={{ xs: '10px', sm: '10px', md: '10px' }}>
            <Grid xs={12}>
              <PageBanner title={t('profile_title')} subtitle={t('profile_subtitle')} isLoading={isLoading}/>
            </Grid>
          {isLoading ? (
              <Grid xs={12}>
                <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                  <CircularProgress />
                </Box>
              </Grid>
          ) : (
            <Grid xs={12}>
              <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box bgcolor="#f2f0f0" p="20px" borderRadius="8px" minHeight="300px" display="flex"
                     flexDirection="column"
                     justifyContent="space-between">
                  <Box bgcolor="#428ee4" p="10px" borderRadius="8px" mb="10px">
                    <Typography variant="h5" fontWeight="600" sx={{ textAlign: 'center' }}>
                      {t('profile')}
                    </Typography>
                  </Box>
                  {!editProfile ? (
                    <>
                      <Typography variant={'body1'}>{t('firstname')}</Typography>
                      <Box bgcolor="#e0e0e0" p="10px" borderRadius="8px" mb="10px">
                        <Typography variant="body1">{userData.firstname}</Typography>
                      </Box>
                      <Typography variant={'body1'}>{t('lastname')}</Typography>
                      <Box bgcolor="#e0e0e0" p="10px" borderRadius="8px" mb="10px">
                        <Typography variant="body1">{userData.lastname}</Typography>
                      </Box>
                      <Typography variant={'body1'}>{t('phone')}</Typography>
                      <Box bgcolor="#e0e0e0" p="10px" borderRadius="8px" mb="10px">
                        <Typography variant="body1">{userData.phone_number}</Typography>
                      </Box>
                      <Button onClick={() => setEditProfile(true)}>{t('edit')}</Button>
                    </>
                  ) : (
                    <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={checkoutSchema}>
                      {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label={t('firstname')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.firstname}
                            name="firstname"
                            style={{ marginBottom: '20px' }}
                            error={!!touched.firstname && !!errors.firstname}
                            helperText={touched.firstname && errors.firstname} />

                          <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label={t('lastname')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.lastname}
                            name="lastname"
                            style={{ marginBottom: '20px' }}
                            error={!!touched.lastname && !!errors.lastname}
                            helperText={touched.lastname && errors.lastname} />

                          <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label={t('phone')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.phone_number}
                            name="phone_number"
                            style={{ marginBottom: '20px' }}
                            error={!!touched.phone_number && !!errors.phone_number}
                            helperText={touched.phone_number && errors.phone_number} />

                          <Box padding={'20px 0 0 0'} display="flex" justifyContent="center">
                            <Button type="submit" color="primary" variant="contained">
                              {t('save_changes')}
                            </Button>
                          </Box>

                          <Box padding={'20px 0 0 0'} display="flex" justifyContent="center">
                            <Button color="secondary" variant="outlined" onClick={() => setEditProfile(false)}>
                              {t('cancel_button')}
                            </Button>
                          </Box>
                        </form>
                      )}
                    </Formik>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box bgcolor="#f2f0f0" p="20px" borderRadius="8px" minHeight="300px" display="flex"
                     flexDirection="column"
                     justifyContent="space-between">
                  <Box bgcolor="#428ee4" p="10px" borderRadius="8px" mb="10px">
                    <Typography variant="h5" fontWeight="600" sx={{ textAlign: 'center' }}>
                      {t('sing_in_and_security')}
                    </Typography>
                  </Box>
                  {!editSignIn ? (
                    <>
                      <Typography variant={'body1'}>{t('email')}</Typography>

                      <Box bgcolor="#e0e0e0" p="10px" borderRadius="8px" mb="10px">
                        <Typography variant="body1">{userData.email}</Typography>
                      </Box>

                      <Typography variant={'body1'}>{t('username')}</Typography>

                      <Box bgcolor="#e0e0e0" p="10px" borderRadius="8px" mb="10px">
                        <Typography variant="body1">{userData.username}</Typography>
                      </Box>

                      <Typography variant={'body1'}>{t('password')}</Typography>
                      <Box bgcolor="#e0e0e0" p="10px" borderRadius="8px" mb="10px">
                        <Typography variant="body1">********</Typography>
                      </Box>
                      <Button onClick={() => setEditSignIn(true)}>{t('edit')}</Button>
                    </>
                  ) : (
                    <Box>
                      <Typography variant={'body1'}>{t('email')}</Typography>
                      <Box bgcolor="#e0e0e0" p="10px" borderRadius="8px" mb="10px">
                        <Typography variant="body1">{userData.email}</Typography>
                      </Box>

                      <Typography variant={'body1'}>{t('username')}</Typography>
                      <Box bgcolor="#e0e0e0" p="10px" borderRadius="8px" mb="10px">
                        <Typography variant="body1">{userData.username}</Typography>
                      </Box>

                      <Box padding={'20px 0 0 0'} display="flex" justifyContent="center">
                        <Button type="submit" color="secondary" variant="contained" onClick={sendChangePasswordEmail}>
                          {t('send_change_password_email')}
                        </Button>
                      </Box>
                      <Box padding={'20px 0 0 0'} display="flex" justifyContent="center">
                        <Button
                          color="secondary" variant="outlined" onClick={() => setEditSignIn(false)}>
                          {t('cancel_button')}
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid><Box bgcolor={'#f2f0f0'} p={'20px'} m={'20px'} borderRadius={'8px'} display={'flex'}>
              <Typography variant="h3" color="#285c9a" fontWeight="bold" m={'10px'}>
                {t('your_referral_link')} "{config.siteURL}/register?referral={userData.ref_id}"
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => navigator.clipboard.writeText(`${config.siteURL}/register?referral=${userData.ref_id}`)}

              >
                {t('copy_to_clipboard')}
              </Button>
            </Box><Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              {isLoadingDialog ? (
                <Box display="flex" justifyContent="center" alignItems="center" m={'10px'}>
                  <CircularProgress />
                </Box>
              ) : (
                <><DialogContent>
                  <Typography variant={'h3'}>
                    {t(dialogMessage)}
                  </Typography>
                </DialogContent><DialogActions>
                  <Button onClick={handleCloseDialog} color="primary" autoFocus>
                    {t('close_button')}
                  </Button>
                </DialogActions></>
              )}
            </Dialog></Grid>
        )}
          </Grid>
        </Box>
  );
};

export default Profile;