import React, { useState } from 'react';
import { Tree } from 'react-d3-tree';
import { Box, CircularProgress, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface TreeStructureProps {
    data: {
        children: never[],
        code: string,
        email: string,
        join_date: string,
        name: string,
        packs_volume: string,
        phone: string,
        rank: string,
        reinvesting: number,
        username: string
    };
}

const TreeStructure: React.FC<TreeStructureProps> = ({data}) => {

        return (
            <Box sx={{ width: '100%', height: '800px', borderColor: '#262626' }}>
                <Tree
                    data={data}
                    orientation="vertical"
                    translate={{ x: 300, y: 200 }}
                    separation={{ siblings: 3, nonSiblings: 3 }}
                    pathFunc="step"
                    collapsible={true}
                    zoomable={true}
                    nodeSize={{ x: 120, y: 80 }}
                    scaleExtent={{ min: 0.1, max: 1 }}
                />
            </Box>
        )
    }

export default TreeStructure;