// src/components/utils/Forms/UserDepositForm.tsx
import React, { useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from "notistack";
import { Box, TextField, MenuItem, Select, InputLabel, FormControl } from "@mui/material";

const UserDepositForm = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [amount, setAmount] = useState(0);
    const [hash, setHash] = useState('');
    const [coin, setCoin] = useState('USDT');
    const [network, setNetwork] = useState('TRC20');
    const [isDepositInfoOpen, setIsDepositInfoOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const handleAmountChange = (event: any) => {
        setAmount(event.target.value);
    };

    const handleHashChange = (event: any) => {
        setHash(event.target.value);
    };

    const handleCoinChange = (event: any) => {
        setCoin(event.target.value);
    };

    const handleNetworkChange = (event: any) => {
        setNetwork(event.target.value);
    };

    return (
        <Grid container spacing={'10px'} rowSpacing={'10px'} columnSpacing={{ xs: '10px', sm: '10px', md: '10px' }}>
            <Grid md={6} xs={12}>
                <Box sx={{backgroundColor: '#262626'}}>
                    <TextField
                        fullWidth
                        label={t('description')}
                        variant="outlined"
                        multiline
                        rows={4}
                    />
                </Box>
            </Grid>
            <Grid md={6} xs={12}>
                <Grid container spacing={'10px'}>
                    <Grid md={12} xs={12}>
                        <Box sx={{backgroundColor: '#262626'}}>
                            <TextField
                                fullWidth
                                label={t('amount')}
                                variant="outlined"
                                value={amount}
                                onChange={handleAmountChange}
                            />
                        </Box>
                    </Grid>
                    <Grid container={true} md={12} xs={12} spacing={'10px'}>
                    <Grid md={12} xs={12}>
                        <Box sx={{backgroundColor: '#262626', height: '100px', width: '100%'}}>

                        </Box>
                    </Grid>
                    <Grid md={12} xs={12}>
                        <Box color={'#262626'}>
                            <TextField
                                fullWidth
                                label={t('hash')}
                                variant="outlined"
                                value={hash}
                                onChange={handleHashChange}
                            />
                        </Box>
                    </Grid>
                    <Grid md={6} xs={12}>
                        <Box color={'#262626'}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel>{t('coin')}</InputLabel>
                                <Select
                                    value={coin}
                                    onChange={handleCoinChange}
                                    label={t('coin')}
                                >
                                    <MenuItem value={'USDT'}>USDT</MenuItem>
                                    <MenuItem value={'BTC'}>BTC</MenuItem>
                                    <MenuItem value={'ETH'}>ETH</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid md={6} xs={12}>
                        <Box color={'#262626'}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel>{t('network')}</InputLabel>
                                <Select
                                    value={network}
                                    onChange={handleNetworkChange}
                                    label={t('network')}
                                >
                                    <MenuItem value={'TRC20'}>TRC20</MenuItem>
                                    <MenuItem value={'ERC20'}>ERC20</MenuItem>
                                    <MenuItem value={'BEP20'}>BEP20</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UserDepositForm;