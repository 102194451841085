import {Box, Skeleton} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Grid from "@mui/material/Unstable_Grid2";
import PageBanner from "../../../utils/PageBanner";
import TreeStructure from "../../../utils/Tree/TreeStructure";
import config from "../../../../config";
import axiosInstance from "../../../../Instances/axiosInstance";
import {useSnackbar} from "notistack";
import ReferralsTable from "../../../utils/Tables/ReferralsTable";

const Network = () => {
    const { t } = useTranslation();
    const [isLoading, setisLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const [referrals, setReferrals] = useState([]);
    const [nerwork, setNerwork] = useState({
            children: [],
            code: "",
            email: "",
            join_date: "",
            name: "",
            packs_volume: "",
            phone: "",
            rank: '',
            reinvesting: 0,
            username: ""
        }
    );

    const getData = useCallback(async () => {
        try {
            const network = await axiosInstance.get(`${config.baseURL}/api/v1/info/user/network`);
            const referrals = await axiosInstance.get(`${config.baseURL}/api/v1/info/user/referrals`);
            setReferrals(referrals.data);
            setNerwork(network.data);
            setisLoading(false);
            return
        }
        catch (error) {
            enqueueSnackbar('Error fetching referrals', { variant: 'error' });
        }
    }, []);



    useEffect(() => {
        getData()
    }, []);


    return (
        <Box m="10px">
            {/* HEADER */}
            <Grid container spacing={'10px'} rowSpacing={'10px'} columnSpacing={{ xs: '10px', sm: '10px', md: '10px' }}>
                <Grid xs={12}>
                    <PageBanner title={t('referral_network_title')} subtitle={t('referral_network_subtitle')} isLoading={isLoading}/>
                </Grid>
                {isLoading ? (
                    <Grid container alignItems="flex-start" xs={12} md={12}>
                        <Grid xs={12} md={12}>
                            <Skeleton variant="rectangular" width="100%" height={800} />
                        </Grid>
                        <Grid xs={12} md={12}>
                            <Skeleton variant="rectangular" width="100%" height={400} />
                        </Grid>
                    </Grid>
                ) : nerwork && (
                    <Grid container alignItems="flex-start" xs={12} md={12}>
                        <Grid xs={12} md={12}>
                            <TreeStructure data={nerwork}/>
                        </Grid>
                        <Grid xs={12} md={12}>
                            <ReferralsTable rows={referrals}/>
                        </Grid>
                    </Grid>

                )}
            </Grid>
        </Box>
    );
};

export default Network;