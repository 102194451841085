import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'axios';
import config from '../../../../config';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import { useTranslation } from 'react-i18next';
import axiosInstance from "../../../../Instances/axiosInstance";
import PageBanner from "../../../utils/PageBanner";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import WalletStatBox from "../../../utils/WalletStatBox";


const Store = (props) => {
  const theme = useTheme();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [price, setPrice] = useState(25);
  const { t, i18n } = useTranslation();
  const [userData, setUserData] = useState({
    firstname: '',
    lastname: '',
    ref_id: '',
    balance: { for_savings: 0.00, for_products: 0.00, for_withdraw: 0.00, total: 0.00 },
  });
  const [token, setToken] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);

  const getData = async () => {
    await axiosInstance.get(
      `/api/v1/info/user/dashboard`,

    ).then(response => {
      if (response.status === 200) {
        response.data.access_token && setToken(response.data.access_token);
        setUserData(response.data);
      }

    }).catch(error => {
      console.error('Error:', error);
    });
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputBlur = (product) => {
    if (inputValue === '') {
      setInputValue(product.quantity);
    } else {
    product.quantity = inputValue;
    }

    setIsEditing(false);
  };

  const handleIncrement = (product) => {
    // Increment the quantity of the product
    product.quantity++;
    // Update the state to trigger a re-render
    setProducts([...products]);
    // Update the price
    setPrice(product.quantity > 10 ? product.price2 : product.price);
  };

  const handleDecrement = (product) => {
    // Decrement the quantity of the product, but not below 0
    if (product.quantity > 0) {
      product.quantity--;
    }
    // Update the state to trigger a re-render
    setProducts([...products]);
    // Update the price
    setPrice(product.quantity > 10 ? product.price2 : product.price);
  };

  const handleProductsPurchase = async () => {
    setOpenDialog(true);
    setIsLoadingDialog(true);
    // Create a new object with product names as keys and quantities as values
    const selectedProducts = {};
    products.forEach(product => {
      if (product.quantity > 0) {
        selectedProducts[product.name] = product.quantity;
      }
    });
    await axiosInstance.post(
      `$/api/v1/purchase/products`,
      {
        products: selectedProducts,
      },

    ).then(response => {
      setDialogMessage(response.data.message);
      setIsLoadingDialog(false);

    }).catch(error => {
      setDialogMessage(error.response.data.message);
      setIsLoadingDialog(false);
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const getProducts = async () => {
    try {
      await axiosInstance.get(
        `/api/v1/info/products/products`,

      ).then(response => {
        setProducts(response.data);
        setIsLoading(false);
      });

    } catch (error) {
      console.error(error);
    }
  };

  const noProductsSelected = () => {
    return !products.some(product => product.quantity > 0);
  };

  useEffect(() => {
    getProducts().then(r => console.log(r));
    getData().then(r => console.log(r));
  }, []);

  return (
    <Box m="10px">
      {/* HEADER */}
      <Grid container spacing={'10px'} rowSpacing={'10px'} columnSpacing={{ xs: '10px', sm: '10px', md: '10px' }}>
        <Grid xs={12}>
          <PageBanner title={t('available_products')} subtitle={t('store_subtile')} isLoading={isLoading}/>
        </Grid>
      {isLoading ? (
          <Grid xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
              <CircularProgress />
            </Box>
          </Grid>
      ) : (
        <Grid xs={12}>
          <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
            <WalletStatBox
                amount={userData.balance.for_products}
                title={t("dashboard_store_funds")}
                icon={<LocalGroceryStoreIcon />}
                isLoading={isLoading}
                inmuted={true}
            />

            <Box display="flex" justifyContent="center" alignItems="center" width={'100%'} height="60px"
                 bgcolor="#285c9a" m={"10px 0 0 0"}>
              <Button variant="contained" color="primary" onClick={handleProductsPurchase} disabled={noProductsSelected()}>
                {t("dashboard_purchase_button")}
              </Button>
            </Box>

          </Grid>

          <Grid container spacing={2} m={'20px'}>
            {products.map((product, index) => (
              <>
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Box bgcolor="#285c9a" p="10px" borderRadius="8px" mb="10px">
                    <Box bgcolor="#e0e0e0" p="10px" borderRadius="8px" mb="10px">
                      <Typography variant="h5" fontWeight="600" sx={{ textAlign: 'center' }}>
                        {t(product.name)}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center">
                    <img className="logo" alt={product.name}
                         src={process.env.PUBLIC_URL + product.image}  height={"100%"} width={"100%"}/>
                    </Box>
                    <Typography variant="body1" color="#FFFFFF" textAlign={'center'} margin={'10px'}>
                      {t(product.description)}
                    </Typography>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Button variant="contained" color="primary" onClick={() => handleDecrement(product)}>
                        -
                      </Button>
                      <Typography variant="h6" color="#FFFFFF" sx={{ textAlign: 'center' }}>
                        {isEditing === product.name ? (
                          <TextField
                            type="number"
                            value={inputValue}
                            onChange={handleInputChange}
                            onBlur={() => handleInputBlur(product)}
                            variant={"outlined"}
                            style={{ width: '70px', color: '#FFFFFF'}}
                          />
                        ) : (
                          <Typography
                            variant="h6"
                            color="#FFFFFF"
                            sx={{ textAlign: 'center' }}
                            onClick={() => setIsEditing(product.name)}
                          >
                            {product.quantity}
                          </Typography>
                        )}
                      </Typography>
                      <Button variant="contained" color="primary" onClick={() => handleIncrement(product)}>
                        +
                      </Button>
                    </Box>
                    <Box bgcolor="#e0e0e0" borderRadius="8px" mb="20px" width={'85%'} margin={'20px'}>
                      <Typography variant="h6" color="primary" sx={{ textAlign: 'center' }}>
                        {/*Price per unit: ${product.quantity > 10 ? product.price2 : product.price}*/}
                        {t("price_per_unit")} ${price}
                      </Typography>
                    </Box>
                    {/*<Button variant="contained" color="primary" onClick={handleProductsPurchase}>*/}
                    {/*  Purchase*/}
                    {/*</Button>*/}
                  </Box>
                </Grid>
              </>
            ))}
          </Grid>
        </Grid>
      )}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isLoadingDialog ? (
          <Box display="flex" justifyContent="center" alignItems="center" m={'10px'}>
            <CircularProgress />
          </Box>
        ) : (
          <><DialogContent>
            <Typography variant={'h3'}>
              {t(dialogMessage)}
            </Typography>
          </DialogContent><DialogActions>
            <Button onClick={handleCloseDialog} color="primary" autoFocus>
              {t('close_button')}
            </Button>
          </DialogActions></>
    )}
      </Dialog>
      </Grid>
    </Box>
  );
};

export default Store;