import React from 'react';
import { GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import '../../assets/css/AuditTable.css';

const CustomToolbar: React.FC = () => {
    return (
        <GridToolbarContainer sx={{position: 'absolute',
            bottom: '0',
            width: '100%',
            background: '#1a3d66',
            color: '#a0aec0',
            display: 'flex',
            justifyContent: 'center',
            padding: '8px 0',
            '.css-1eed5fa-MuiInputBase-root-MuiInput-root': {
                color: '#a0aec0',
            }
        }}>
            <GridToolbarExport sx={{ marginRight: 'auto', alignSelf: 'center' }} />
            <GridToolbarQuickFilter sx={{ marginRight: 'auto', alignSelf: 'center', color: '#FFFFFF0' }} />
        </GridToolbarContainer>
    );
};

export default CustomToolbar;