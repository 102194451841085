import React from 'react';
import { CssBaseline } from '@mui/material';
import Navbar from '../../utils/Navbar/Navbar';
import Sidebar from '../../utils/Sidebar/Sidebar';
import Home from './Home';
import Packs from "./PurchasePacks";
import OnGoingAudits from '../admin/OnGoingAudits/OnGoingAudits'
import SearchUserInfo from "../admin/Search/SearchUserInfo";
import AddAudits from "../admin/AddAudits/AddAudits";
import {Route, Routes} from "react-router-dom";
import Profile from "./profile";
import FAQ from "./faq";
import ActivePacks from "./activepacks";
// import Deposit from "./deposit";
import Deposit from "./deposit/Deposit";
import Withdraw from "./withdraw";
import Store from "./store";
import Promotions from "./promotions";
import RankPrizes from "./rankprizes";
import Suggestions from "./suggestions";
import PromotionalPacks from "./promotionalpacks";
import ReferralNetwork from "./network/ReferralNetwork";
import History from "./history";

const Main = () => {
    return (
        <><CssBaseline/>
            <Sidebar>
                <div style={{height: '100%', width: '100%'}}>
                    <main>
                        <Navbar/>
                        <Routes>
                            <Route path={"/"} element={<Home/>} />
                            <Route path="/history" element={<History />} />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/faq" element={<FAQ />} />
                            <Route path="/packs" element={<Packs />} />
                            <Route path="/activepacks" element={<ActivePacks  />} />
                            <Route path="/network" element={<ReferralNetwork />} />
                            <Route path={'/deposit'} element={<Deposit />} />
                            <Route path={'/withdraw'} element={<Withdraw />} />
                            <Route path={'/store'} element={<Store />} />
                            <Route path="/promotions" element={<Promotions  />} />
                            <Route path="/rankprizes" element={<RankPrizes />} />
                            <Route path="/suggestions" element={<Suggestions />} />
                            <Route path="/packs/promotional" element={<PromotionalPacks />} />
                            <Route path="/profile" element={<Profile />} />
                            <Route path={"/*"} element={<Home />} />
                        </Routes>
                    </main>
                </div>
            </Sidebar>
        </>
    );
};

export { Main };